
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import constRouter from '@/constants/constRouter';
import { mapGetters } from 'vuex';
import { allListBank } from '@/apis/ApiBank';
import { withDrawPool } from '@/apis/ApiPool';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseAutocomplete from '@/components/Base/BaseAutoComplete.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import IconLock from '@/components/Icon/IconLock.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { formatAccountBankNumber } from '@/utils/index';

export default defineComponent({
  components: {
    DefaultLayout,
    BaseModal,
    BaseButton,
    BaseAutocomplete,
    BaseInput,
    IconLock,
    BaseTextError,
  },
  setup() {
    const loading = ref(false);
    const isGetResult = ref(false);
    const openModal = ref(false);
    const dataModal = ref({});
    const accountNumber = ref('');
    const accountName = ref('');
    const bankName = ref('');
    const bankId = ref('');
    const bankList = ref([]);
    const errorMessagesBank = ref('');
    const errorMessagesBankName = ref('');
    const errorMessagesBankNumber = ref('');
    const errorMessages = ref('');
    const fetchBankAccountList = () => {
      allListBank()
        .then((res) => {
          const data: any = [];
          res.data.forEach((item: any) => {
            data.push({
              name: item.name + ` (${item.shortName} - ${item.code})`,
              id: item.id,
            });
          });
          bankList.value = data;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchBankAccountList();
    return {
      dataModal,
      loading,
      openModal,
      isGetResult,
      accountNumber,
      accountName,
      bankName,
      bankId,
      bankList,
      errorMessagesBank,
      errorMessagesBankName,
      errorMessagesBankNumber,
      formatAccountBankNumber,
      errorMessages,
    };
  },
  computed: {
    ...mapGetters({
      data: 'bank/getBankAccountList',
    }),
  },
  watch: {
    accountName(value) {
      this.accountName = value.toUpperCase();
    },
  },
  methods: {
    closeModal() {
      this.openModal = false;
      this.$router.push({
        name: constRouter.OVERVIEW_PACEKAGE.name,
        params: { id: this.$route.params.id },
      });
    },
    handleChooseAccount() {
      this.errorMessagesBank = '';
      this.errorMessagesBankName = '';
      this.errorMessagesBankNumber = '';
      if (!this.bankId) {
        this.errorMessagesBank = 'Bạn chưa chọn ngân hàng';
      }
      if (!this.accountName) {
        this.errorMessagesBankName = 'Bạn chưa nhập tên chủ tài khoản';
      }
      if (!this.accountNumber) {
        this.errorMessagesBankNumber = 'Bạn chưa nhập số tài khoản';
      }
      if (!this.bankId || !this.accountName || !this.accountNumber) {
        return false;
      }
      const poolId: any = this.$route.params.id;
      this.loading = true;
      withDrawPool(poolId, {
        accountNumber: this.accountNumber,
        accountName: this.accountName.toUpperCase(),
        bankId: this.bankId,
      })
        .then(() => {
          this.openModal = true;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessages = err?.data?.message;
          this.loading = false;
        });
    },
  },
});
